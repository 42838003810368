const { buildNamespacesMap } = require('@wix/wix-code-viewer-utils');
const { createClient } = require('@wix/sdk/client');
const { site } = require('@wix/site');
const path = require('path');

const WIX_CODE_APP_DEF_ID = '675bbcef-18d8-41f5-800e-131ec9e08762';

const createGlobals = ({
  active$wBiFactory,
  $w,
  wixSdk,
  userConsole,
  getAppDefIdFromPackageName,
}) => {
  const wrapped$w = active$wBiFactory.wrapFunctionReturnValueWithBi($w);
  wrapped$w.at = active$wBiFactory.wrapFunctionCallWithBi($w.at, $w);

  const wrappedWixSdk = buildNamespacesMap(
    wixSdk,
    self.fetch.bind(self),
    active$wBiFactory.wrapObjectPropertiesWithBi,
  );
  const clientSdk = {
    async invoke({ applicationId, namespace, method, args }) {
      if (wixSdk?.[namespace]) {
        // method might be in the format of 'currentMember.getProfile'
        const methods = method.split('.');
        let currentObject = wixSdk[namespace];

        for (const methodPart of methods) {
          if (currentObject?.[methodPart] !== undefined) {
            currentObject = currentObject[methodPart];
          } else {
            console.error('Wix Site SDK - method not found', {
              applicationId,
              namespace,
              method,
            });
            return undefined;
          }
        }
        if (typeof currentObject === 'function') {
          return currentObject(...args);
        } else {
          // might be undefined if console.error was called above
          return currentObject;
        }
      } else {
        console.error('Wix Site SDK - namespace not found', {
          applicationId,
          namespace,
          method,
        });
      }
    },
  };

  const sdkBaseUrl = getWixClientApiBaseUrl(wixSdk);

  const client = createClient({
    host: {
      ...site.host({ applicationId: WIX_CODE_APP_DEF_ID, clientSdk }),
      apiBaseUrl: sdkBaseUrl,
    },
    auth: {
      getAuthHeaders() {
        return {
          headers: {
            Authorization:
              wixSdk.elementorySupport.getRequestOptions().headers
                .Authorization,
          },
        };
      },
    },
  });

  return {
    $w: wrapped$w,
    $ns: wrappedWixSdk,
    console: userConsole,
    $wixContext: {
      version: 3,
      client,
      clientSdk,
    },
    elementorySupport: wixSdk.elementorySupport,
    getAppDefIdFromPackageName, // corresponding change in the bundling side can be found here: https://github.com/wix-private/cloud-runtime/pull/4025
  };
};

function getWixClientApiBaseUrl(wixSdk) {
  let sdkBaseUrl = wixSdk?.location?.baseUrl;

  // When we in iframe, we should use the iframe URL which looks like https://<guid>.pub.wix-code.com
  if (wixSdk?.window?.viewMode === 'Preview') {
    sdkBaseUrl = wixSdk?.elementorySupport?.baseUrl;
  }

  if (!sdkBaseUrl) {
    return '';
  }

  // clean the protocol from the URL
  const parsedUrlObject = new URL(sdkBaseUrl);
  sdkBaseUrl = path.join(
    parsedUrlObject.hostname,
    parsedUrlObject.pathname !== '/' ? parsedUrlObject.pathname : '',
  );

  return sdkBaseUrl;
}

module.exports = {
  createGlobals,
};
